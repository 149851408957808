const getProjects = async function() {
    const response = await fetch("./data.json")
    if (response.status == 200) {
        let data = await response.json()
        return data.projects
    } else {
        new Error(response.statusText)
    }
} 

const getWhoIam = async function() {
    const response = await fetch("./data.json")
    if (response.status == 200) {
        let data = await response.json()
        return data.aPropos
    } else {
        new Error(response.statusText)
    }
} 



export { getProjects, getWhoIam }
